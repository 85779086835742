import algoliasearch from "algoliasearch"

import { API } from "aws-amplify"

export const getSearchClient = doSearch => {
  if (doSearch)
    return algoliasearch("RO2C5YW75T", "cb49e4ad295930902f55bd7f8114ab53")
  else
    return {
      search(requests) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        })
      },
    }
}

const cache = new Map()
const promiseCache = new Map()

export const getCustomSearchClient = (doSearch, start, end) => {
  const doRequest = body => {
    // console.log("doing request", body)
    return fetch(
      // prod https://sxvls3nwxi.execute-api.eu-west-1.amazonaws.com/master/actions/villa/search?start=${start}&end=${end}
      // dev  https://u8pa62wlna.execute-api.eu-west-1.amazonaws.com/dev/actions/villa/search?start=${start}&end=${end}
      `https://sxvls3nwxi.execute-api.eu-west-1.amazonaws.com/master/actions/villa/search?start=${start}&end=${end}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      }
    ).then(res => res.json())
    // return API.post("ibizahighlifeapi", "/actions/villa/search", {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   queryStringParameters: {
    //     start,
    //     end,
    //   },
    //   body: JSON.stringify(body),
    // })
  }

  const searchClient = {
    async search(requests) {
      const body = JSON.stringify({ requests })

      const cached = cache.get(body)

      if (cached) {
        return cached
      }

      const promiseCached = promiseCache.get(body)

      if (promiseCached) {
        return promiseCached
      }

      const promise = doRequest(body)

      promiseCache.set(body, promise)

      const response = await doRequest(body)

      cache.set(body, response)

      promiseCache.delete(body)

      return response
    },
  }

  return searchClient
}
export const getCustomSearchClient2 = (doSearch, start, end) => {
  // if (!doSearch) return

  return {
    search(requests) {
      // if (!doSearch) {
      //   console.log("returning no result")
      //   return Promise.resolve({
      //     results: requests.map(() => ({
      //       hits: [],
      //       nbHits: 0,
      //       nbPages: 0,
      //       page: 0,
      //       processingTimeMS: 0,
      //     })),
      //   })
      // }

      return fetch(
        `https://u8pa62wlna.execute-api.eu-west-1.amazonaws.com/dev/actions/villa/search?start=${start}&end=${end}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ requests }),
        }
      ).then(res => res.json())
    },
  }
}
